export enum EventTemplateRelevance {
  STANDARD = 'STANDARD',
  PROMOTED = 'PROMOTED',
  HAND_PICKED = 'HAND_PICKED',
}

export namespace EventTemplateRelevance {
  export function getAllEventTemplateRelevances(): EventTemplateRelevance[] {
    return [
      EventTemplateRelevance.STANDARD,
      EventTemplateRelevance.PROMOTED,
      EventTemplateRelevance.HAND_PICKED,
    ];
  }

  export function getDisplayName(relevance?: EventTemplateRelevance): string {
    switch (relevance) {
      case EventTemplateRelevance.STANDARD:
        return 'APP.DESIGN_TEMPLATES.RELEVANCE.STANDARD';
      case EventTemplateRelevance.PROMOTED:
        return 'APP.DESIGN_TEMPLATES.RELEVANCE.PROMOTED';
      case EventTemplateRelevance.HAND_PICKED:
        return 'APP.DESIGN_TEMPLATES.RELEVANCE.HAND_PICKED';
      default:
        return '';
    }
  }
}
